span
<template>
	<div class="app-container" v-loading="loading">
		<el-header class="new-header">
			<div class="new-header-left">
				<span>客户详情</span>
				<span class="back" @click="Detailsbacktolist">返回</span>
			</div>
			<div class="new-beader-right">
				<!-- <el-button
          @click="handleToCall"
          type="primary"
          plain
          icon="el-icon-phone"
          v-if="form.customerPhone && fromMine == 1"
          >拨打电话</el-button
        > -->
			</div>
		</el-header>
		<el-main style="background: #fff">
			<el-descriptions title="基本信息" direction="vertical" :column="4" border>
				<template slot="extra">
					<el-button
						type="primary"
						size="small"
						@click="handleInitAddFollowInfo"
						>添加跟进</el-button
					>
					<el-button @click="handleInitDistribute" type="primary" size="small"
						>派单</el-button
					>
					<el-button type="primary" size="small" @click="handleLinkToModify"
						>编辑</el-button
					>
				</template>
				<el-descriptions-item label="客户编号"
					>{{ form.customerId }}
				</el-descriptions-item>
				<el-descriptions-item label="客户状态">
					<el-select
						id="state"
						v-model="form.customerState"
						placeholder="状态"
						filterable
						@change="handleModifyState"
					>
						<el-option
							v-for="item in customerStateList"
							:key="item.id"
							:value="item.id"
							:label="item.value"
						></el-option>
					</el-select>
				</el-descriptions-item>
				<el-descriptions-item label="创建者"
					>{{ form.createName }}
				</el-descriptions-item>
				<el-descriptions-item label="创建时间"
					>{{ form.createTime }}
				</el-descriptions-item>
				<el-descriptions-item label="销售"
					>{{ form.salesName }}
				</el-descriptions-item>
				<el-descriptions-item label="客户来源"
					>{{ form.customerSourceName }}
				</el-descriptions-item>
				<el-descriptions-item label="标签">
					<el-select
						id="state"
						v-model="form.customerType"
						placeholder="标签"
						filterable
						@change="handleModifyType"
					>
						<el-option
							v-for="item in customerTypeList"
							:key="item.id"
							:value="item.id"
							:label="item.value"
						></el-option>
					</el-select>
				</el-descriptions-item>
				<el-descriptions-item label="备注"
					>{{ form.remark }}
				</el-descriptions-item>
				<el-descriptions-item label="姓名"
					>{{ form.customerName }}
				</el-descriptions-item>
				<el-descriptions-item label="手机号"
					>{{ form.customerPhone }}
				</el-descriptions-item>
				<el-descriptions-item label="公司名称"
					>{{ form.companyName }}
				</el-descriptions-item>
				<el-descriptions-item label="所在区域"
					>{{ form.region }}
				</el-descriptions-item>
				<el-descriptions-item label="详细地址"
					>{{ form.address }}
				</el-descriptions-item>
				<el-descriptions-item label="职业"
					>{{ form.profession }}
				</el-descriptions-item>
				<el-descriptions-item label="行业"
					>{{ form.industry }}
				</el-descriptions-item>
			</el-descriptions>
			<div class="title">跟进信息</div>
			<div class="follow-block">
				<el-timeline>
					<el-timeline-item
						v-for="(activity, index) in followInfo"
						:key="index"
						:timestamp="activity.overviewDayTime + activity.overviewWeekTime"
						placement="top"
					>
						<el-card
							style="margin-bottom: 10px"
							v-for="(item, i) in activity.overviewMinuteArray"
							:key="i"
						>
							<div class="time">时间：{{ item.overviewMinuteTime }}</div>
							<div class="content">
								跟进类型：{{ item.overviewBusinessDetails.followTypeName }}
							</div>
							<div class="content">
								操作人：{{ item.overviewBusinessDetails.operationName }}
							</div>

							<div class="content">
								跟进内容：
								<span
									class="phone"
									v-if="item.overviewBusinessDetails.followType === 6"
									@click="handleViewCallRecord(item)"
								>
									{{ item.overviewBusinessDetails.content + ',点击查看>>' }}
								</span>
								<span v-else>
									{{ item.overviewBusinessDetails.content }}
								</span>
							</div>
						</el-card>
					</el-timeline-item>
				</el-timeline>
			</div>
		</el-main>

		<el-dialog
			title="客户派单"
			:close-on-click-modal="false"
			:visible.sync="dialogForDistribute"
			top="30vh"
			width="30%"
		>
			<el-row type="flex" justify="center">
				<el-col :span="16">
					<el-form
						ref="distributeForm"
						:model="distributeForm"
						label-position="right"
						label-width="50px"
					>
						<el-form-item
							label="销售"
							prop="salesId"
							:rules="[
								{ required: true, message: '请选择销售', trigger: 'change' },
							]"
						>
							<el-select
								v-model="distributeForm.salesId"
								placeholder="销售"
								filterable
							>
								<el-option
									v-for="item in sales"
									:key="item.userId"
									:value="item.userId"
									:label="item.userName"
								></el-option>
							</el-select>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<div slot="footer">
				<el-button type="primary" @click="handleDistribute">分派</el-button>
			</div>
		</el-dialog>

		<el-dialog
			title="添加跟进"
			:close-on-click-modal="false"
			:visible.sync="dialogForFollow"
			top="30vh"
			width="30%"
		>
			<el-row type="flex" justify="center">
				<el-col :span="16">
					<el-form
						:model="followInfoForm"
						ref="followInfoForm"
						:rules="rules"
						label-width="100px"
						label-position="right"
					>
						<el-form-item label="跟进类型" prop="followType">
							<el-select
								v-model="followInfoForm.followType"
								filterable
								placeholder="跟进类型"
								style="width: 100%"
							>
								<el-option
									v-for="item in CustomerFollowTypeList"
									:key="item.id"
									:value="item.id"
									:label="item.value"
								></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="跟进内容" prop="content">
							<el-input
								type="textarea"
								v-model="followInfoForm.content"
								placeholder="跟进内容"
							>
							</el-input>
						</el-form-item>
						<el-form-item label="跟进时间" prop="followTime">
							<el-date-picker
								type="datetime"
								v-model="followInfoForm.followTime"
								placeholder="跟进时间"
								format="yyyy-MM-dd HH:mm:ss"
							>
							</el-date-picker>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<div slot="footer">
				<el-button type="primary" @click="handleAddFollowInfo">保存</el-button>
			</div>
		</el-dialog>
		<el-dialog
			title="通话详情"
			:close-on-click-modal="false"
			:visible.sync="dialogForRecord"
			top="30vh"
			width="30%"
		>
			<el-row type="flex" justify="center">
				<el-col :span="22">
					<el-descriptions :column="1" border>
						<el-descriptions-item label="主叫号码">{{
							recordDetails.caller
						}}</el-descriptions-item>
						<el-descriptions-item label="被叫号码">{{
							recordDetails.callee
						}}</el-descriptions-item>
						<el-descriptions-item label="呼出时间">{{
							recordDetails.createTime
						}}</el-descriptions-item>
						<el-descriptions-item label="通话时长">{{
							recordDetails.callTime
						}}</el-descriptions-item>
						<el-descriptions-item label="呼出状态">{{
							recordDetails.callState
						}}</el-descriptions-item>
						<el-descriptions-item label="录音">
							<audio
								style="width: 300px"
								v-if="recordDetails.recordUrl"
								:src="recordDetails.recordUrl"
								preload="none"
								controls="controls"
							></audio>
						</el-descriptions-item>
					</el-descriptions>
				</el-col>
			</el-row>
		</el-dialog>
	</div>
</template>
<script>
	import {
		createCustomerFollow,
		customerAssignment,
		editCustomerStatus,
		getCustomerDetails,
		getCustomerFollowList,
		getRecordByUserIdAndSessionId,
		getUserListOfMerchantByPage,
		initCustomerFollow,
		initCustomerInfo,
	} from '../../api'

	export default {
		name: 'CustomerDetails',
		data() {
			return {
				userId: this.$store.getters.userId,
				id: this.$route.query.id,
				loading: false,
				dialogForState: false,
				dialogForDistribute: false,
				dialogForFollow: false,
				customerStateList: [],
				customerTypeList: [],
				fromMine: this.$route.query.fromMine,
				dialogForRecord: false,
				recordDetails: {
					caller: '',
					callee: '',
					callTime: '',
					callState: '',
					caller: '',
					recordUrl: '',
				},
				form: {
					customerId: '',
					customerName: '',
					customerPhone: '',
					merchantId: '',
					provinceId: '',
					districtId: '',
					cityId: '',
					region: '',
					address: '',
					companyName: '',
					industry: '',
					profession: '',
					salesId: '',
					remark: '',
					customerState: '',
					customerStateName: '',
					customerType: '',
					customerTypeName: '',
					customerSource: '',
					customerSourceName: '',
					followTime: '',
				},
				followInfo: [],
				CustomerFollowTypeList: [],
				followInfoForm: {
					followType: null,
					content: null,
					customerId: null,
					followTime: null,
				},
				distributeForm: {
					salesId: null,
				},
				sales: [],
				rules: {
					followType: [
						{ required: true, message: '请选择跟进类型', trigger: 'change' },
					],
					content: [
						{ required: true, message: '请输入跟进内容', trigger: 'blur' },
					],
		
				},
			}
		},
		methods: {
			async handleViewCallRecord(item) {
				const data = await getRecordByUserIdAndSessionId({
					userId: this.userId,
					sessionId:
						item.overviewBusinessDetails.sessionId ||
						item.overviewBusinessDetails.callId,
				})
				this.recordDetails = Object.assign(this.recordDetails, data)
				this.dialogForRecord = true
			},
			handleToCall() {
				if (this.form.customerPhone) {
					this.$router.push({
						name: 'call',
						query: { customerPhone: this.form.customerPhone },
					})
				}
			},
			handleDistribute() {
				console.log(this.$refs.distributeForm)
				this.$refs.distributeForm.validate((valid) => {
					if (valid) {
						customerAssignment({
							userId: this.userId,
							customerId: this.id,
							salesId: this.distributeForm.salesId,
						}).then((data) => {
							if (data) {
								this.$notify({
									type: 'success',
									message: '客户分派成功',
									title: '成功',
								})
								this.dialogForDistribute = false
								this.getDetailsData()
							}
						})
					}
				})
			},
			async handleInitDistribute() {
				if (this.sales.length) {
					this.dialogForDistribute = true
					this.distributeForm.salesId = this.form.salesId
					this.$nextTick(() => {
						this.$refs.distributeForm.clearValidate()
					})
				} else {
					let sales = await getUserListOfMerchantByPage({
						current: 1,
						size: 500,
						params: { userId: this.userId },
					})
					this.sales = sales.records
					this.dialogForDistribute = true
					this.distributeForm.salesId = this.form.salesId
					this.$nextTick(() => {
						this.$refs.distributeForm.clearValidate()
					})
				}
			},
			async handleInitAddFollowInfo() {
				if (this.CustomerFollowTypeList.length) {
					this.dialogForFollow = true
					this.$nextTick(() => {
						this.$refs.followInfoForm.clearValidate()
					})
				} else {
					const data = await initCustomerFollow({ userId: this.userId })
					this.CustomerFollowTypeList = data.CustomerFollowTypeList
					this.dialogForFollow = true
					this.$nextTick(() => {
						this.$refs.followInfoForm.clearValidate()
					})
				}
			},
			handleAddFollowInfo() {
				this.$refs.followInfoForm.validate((valid) => {
					if (valid) {
						let param = {
							userId: this.userId,
							...this.followInfoForm,
							customerId: this.id,
						}
						createCustomerFollow(param).then((data) => {
							if (data) {
								this.$notify({
									type: 'success',
									message: '添加跟进成功',
									title: '成功',
								})
								this.dialogForFollow = false
								this.followInfoForm.followType = null
								this.followInfoForm.content = null
								this.followInfoForm.followTime = null
								this.loadCustomerFollowInfo()
							}
						})
					}
				})
			},
			handleLinkToModify() {
				this.$router.push({
					name: 'modify-customer',
					query: {
						id: this.id,
					},
				})
			},
			async loadCustomerFollowInfo() {
				const data = await getCustomerFollowList({
					customerId: this.id,
					userId: this.userId,
				})
				this.followInfo = data
			},
			async handleModifyState(val) {
				let param = {
					userId: this.userId,
					customerId: this.id,
					customerState: val,
				}
				let data = await editCustomerStatus(param)
				if (data) {
					this.$notify({
						type: 'success',
						message: '修改客户状态成功',
						title: '成功',
					})
				}
			},
			async handleModifyType(val) {
				let param = {
					userId: this.userId,
					customerId: this.id,
					customerType: val,
				}
				let data = await editCustomerStatus(param)
				if (data) {
					this.$notify({
						type: 'success',
						message: '修改标签成功',
						title: '成功',
					})
				}
			},
			async initSelections() {
				let data = await initCustomerInfo()
				this.customerStateList = data.customerStateList
				this.customerTypeList = data.customerTypeList
			},
			Detailsbacktolist() {
				this.$router.back()
			},
			async getDetailsData() {
				this.loading = true
				let data = await getCustomerDetails({
					userId: this.userId,
					customerId: this.id,
				})
				this.loading = false
				Object.assign(this.form, data)
			},
		},
		created() {
			this.getDetailsData()
			this.initSelections()
			this.loadCustomerFollowInfo()
		},
	}
</script>
<style lang="scss" scoped>
	.back {
		font-size: 12px;
		cursor: pointer;
		color: rgb(64, 158, 255);
		margin-left: 4px;
	}

	.title {
		font-size: 16px;
		font-weight: bold;
		margin: 20px 0;
	}

	.follow-block {
		width: 400px;

		.content {
			color: #111;
			line-height: 2;
			font-size: 13px;
		}
		.time {
			color: rgba($color: #000000, $alpha: 0.7);
		}
		.phone {
			cursor: pointer;
			color: #409eff;
		}
	}
</style>
